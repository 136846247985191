import React, {useEffect, useState} from "react";
import {ControlLayout} from "../../layouts/ControlLayout";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup, IconButton,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {Delete, Fullscreen, Refresh} from "@mui/icons-material";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {Link} from "react-router-dom";
import axios from "axios";

export const ControlFoto = () => {
    const [active, activeLoading, activeError] = useDocument(firestore.doc('test/sezioniAperte'))
    const [{data: images, loading, error}, refetch] = useAxios(`${API_URL}photo/approved`)
    const [open, setOpen] = useState(false)

    const deleteImage = async (id) => {
        await axios.post(`${API_URL}photo/${id}/block`)
            .finally(() => refetch())
    }

    const handleActive = async () => {
        await axios.post(`${API_URL}actions`, {action: 'foto', value: !active.data().foto})
            .finally(() => refetch())
    }

    const reset = async () => {
        await axios.post(`${API_URL}clear/foto`, {})
            .then(() => setOpen(!open))
            .finally(() => refetch())
    }

    return (
        <ControlLayout>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogContent sx={{backgroundColor:'#121212'}}>
                    <DialogTitle>
                        Vuoi eliminare tutte le foto?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} variant={"outlined"}>no</Button>
                        <Button onClick={reset} variant={"contained"} color={"error"}>Si</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Typography variant={'h4'} fontWeight={"bold"}>Foto</Typography>
            <Divider sx={{my: 2}}/>
            <Box sx={{pb: 2}}>
                <Typography variant={"h6"}>
                    Attiva/disattiva sezione
                </Typography>
                {activeLoading ? <CircularProgress/>
                    :
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={active.data().foto}
                                                           onChange={(e) => handleActive(e.target.checked)}/>}
                                          label={active.data().foto ? 'Invio foto APERTO' : "Invio foto CHIUSO"}/>
                    </FormGroup>}
            </Box>
            <Divider/>
            <Typography variant={"h6"} sx={{pt: 2}}>
                Moderazione
            </Typography>
            <Stack sx={{py: 1}} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={() => refetch()} variant={"contained"} startIcon={<Refresh/>}>Ricarica</Button>
                <Button onClick={() => setOpen(!open)} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Reset risultati</Button>
            </Stack>

            <Box sx={{py: 2}}>
                {loading ? <CircularProgress/>
                    :
                    <Stack direction={'row'} flexWrap={'wrap'} style={{
                        width: '100%',
                    }}>
                        {
                            (!loading && !error && images) &&
                            images.map((image, i) =>
                                <div key={i} style={{
                                    width: '10rem',
                                    height: '12rem',
                                    position: "relative",
                                }}>
                                    <Stack sx={{position: 'absolute', bottom: 0, width: '100%', p: 1}} direction={'row'}
                                           justifyContent={"space-between"}>
                                        <IconButton onClick={() => deleteImage(image.id)}
                                                    sx={{backgroundColor: 'error.main'}}>
                                            <Delete fontSize="inherit" color={"white"}/>
                                        </IconButton>
                                        <IconButton component={'a'}
                                                    href={`https://storage.googleapis.com/bea-2022/${image.filename}`}
                                                    target={"_blank"} sx={{backgroundColor: 'primary.main'}}>
                                            <Fullscreen/>
                                        </IconButton>
                                    </Stack>
                                    <img style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                         src={`https://storage.googleapis.com/bea-2022/${image.filename}`}/>
                                </div>
                            )
                        }
                    </Stack>
                }
            </Box>
        </ControlLayout>
    )
}
