import React from "react";
import {Box, Grow, Typography} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import beaItaliaTheme from "../../themes/beaItaliaTheme";

export const Messaggi = () => {
    const [messagePlaying, messageLoading, messageError] = useDocument(firestore.doc('test/message/'))

    console.log("messagePlaying:",messagePlaying?.data()?.message)
    return(
        messagePlaying?.data()?.message ?
        <Grow in={true} exit={true} timeout={900}>
            <div>
            <div className={"triangoloEq"}></div>
            <Box py={5} px={5} borderRadius={'2rem'}
                 sx={{
                background: beaItaliaTheme.palette.text.primary,
                boxShadow: `11px 11px 10px 0px ${beaItaliaTheme.palette.primary.main}`
            }} maxWidth={'650px'} maxHeight={'490px'}>
                <Typography variant={'h2'} color={beaItaliaTheme.palette.primary.main}
                            fontWeight={'bold'}
                            textAlign={'center'}>
                    {messagePlaying?.data()?.message}
                </Typography>
        </Box>
            </div>
    </Grow> : null
  )
}
