import React from "react";
import {Box, Grow, Typography} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import beaItaliaTheme from "../../themes/beaItaliaTheme";

export const MessaggiOnlyText = () => {
    const [messagePlaying, messageLoading, messageError] = useDocument(firestore.doc('test/message/'))

    console.log("messagePlaying:",messagePlaying?.data()?.message)
    return(
        messagePlaying?.data()?.message ?
        <Grow in={true} exit={true} timeout={900}>
            <div>
                <Box p={1} borderRadius={'2rem'} display={'flex'} justifyContent={'right'} alignItems={'center'}
                     sx={{
                         background: 'transparent',//beaItaliaTheme.palette.text.primary,
                         //boxShadow: `11px 11px 10px 0px ${beaItaliaTheme.palette.primary.main}`
                     }} maxWidth={'470px'} height={'55px'}>
                    <Typography variant={'h6'} color={beaItaliaTheme.palette.text.primary}
                                fontWeight={'bold'}
                                sx={{lineHeight: '22px'}}
                                textAlign={'right'}>
                        {messagePlaying?.data()?.message}
                    </Typography>
                </Box>
            </div>
    </Grow> : null
  )
}
